import { authenticatedInstance } from './axiosInstance';

export const userToken = async (companyId) => {
  return await authenticatedInstance.get<string>(
    `/companies/${companyId}/chat/token`
  );
};

export const createChannel = async (fromCompanyId, toCompanyId) => {
  return await authenticatedInstance.post(
    `/companies/${fromCompanyId}/chat/createChannel`,
    {
      toCompanyId,
    }
  );
};
