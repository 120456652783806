import { createContext, useContext, useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import { useSnackbar } from 'notistack';
import debounce from 'lodash.debounce';

import { useCurrentCompanyContext } from './CurrentCompany';
import { userToken } from '../services/chatService';
import Link from 'next/link';
import { routes } from '../config/routes';
import { Button } from '@mui/material';

interface ChatContextProps {
  chatClient: StreamChat;
  unreadCount: number;
}

const ChatContext = createContext<ChatContextProps>({
  chatClient: undefined,
  unreadCount: 0,
});

const apiKey = process.env.NEXT_PUBLIC_GETSTREAM_API_KEY;

export const ChatProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [chatClient, setChatClient] = useState<StreamChat>();
  const [listeners, setListeners] = useState<
    {
      unsubscribe: () => void;
    }[]
  >([]);
  const { currentCompanyId } = useCurrentCompanyContext();
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    const showNewMessageSnackbar = () =>
      enqueueSnackbar('You have new messages', {
        variant: 'info',
        key: 'new-message',
        preventDuplicate: true,
        action: () => (
          <Link href={routes.INBOX} passHref>
            <Button variant="text">View</Button>
          </Link>
        ),
      });

    async function initChat() {
      if (currentCompanyId) {
        if (chatClient && chatClient.userID) {
          listeners.forEach((l) => {
            l.unsubscribe();
          });

          setListeners([]);
          setChatClient(undefined);

          await chatClient.disconnectUser();
        }

        const userId = String(currentCompanyId);
        const token = (await userToken(currentCompanyId)).data;

        const newClient = StreamChat.getInstance(apiKey);

        if (typeof window !== 'undefined') {
          const updateReadCount = debounce(async () => {
            const unreadCountRes = await newClient.getUnreadCount(
              newClient.userID
            );
            setUnreadCount(unreadCountRes.channels.length);
          });

          await newClient.connectUser({ id: userId }, token);

          const newMessageListener = newClient.on('message.new', (event) => {
            if (event?.user.id !== userId) {
              updateReadCount();
              showNewMessageSnackbar();
            }
          });

          setListeners([...listeners, newMessageListener]);

          const newMessageNotificationListener = newClient.on(
            'notification.message_new',
            (event) => {
              if (event?.message?.user.id !== userId) {
                updateReadCount();
                showNewMessageSnackbar();
              }
            }
          );

          setListeners([...listeners, newMessageNotificationListener]);

          const readListener = newClient.on('notification.mark_read', () => {
            updateReadCount();
          });

          setListeners([...listeners, readListener]);

          const unreadCountRes = await newClient.getUnreadCount(userId);
          setUnreadCount(unreadCountRes.channels.length);
        }

        setChatClient(newClient);
      }
    }

    initChat();
  }, [currentCompanyId, enqueueSnackbar]);

  return (
    <ChatContext.Provider value={{ chatClient, unreadCount }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);
