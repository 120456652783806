import { useQuery } from '@tanstack/react-query';

import { getUserCompanies } from '../userService';
import { useAuth } from '../../context/Auth';

export const QUERY_KEY = (id) => ['GET_USER_COMPANIES', id];

export const useUserCompanies = () => {
  const { isAuthenticated, user } = useAuth();
  const query = useQuery(QUERY_KEY(user?.id), getUserCompanies, {
    enabled: isAuthenticated,
  });

  return query;
};
