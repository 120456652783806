import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { Facebook, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

export const Footer = () => {
  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const { t } = useTranslation(['common']);
  const { locale } = useRouter();
  const termsUrl =
    locale === 'es'
      ? `${process.env.NEXT_PUBLIC_CONNECT_AMERICAS_URL}/es/content/t%C3%A9rminos-y-condiciones`
      : `${process.env.NEXT_PUBLIC_CONNECT_AMERICAS_URL}/content/terms-and-conditions-0`;
  const privacyUrl =
    locale === 'es'
      ? `${process.env.NEXT_PUBLIC_CONNECT_AMERICAS_URL}/es/content/pol%C3%ADticas-de-privacidad`
      : `${process.env.NEXT_PUBLIC_CONNECT_AMERICAS_URL}/content/privacy-policy`;
  const facebookUrl = process.env.NEXT_PUBLIC_FACEBOOK_URL;
  const linkedInUrl = process.env.NEXT_PUBLIC_LINKEDIN_URL;
  const youtubeUrl = process.env.NEXT_PUBLIC_YOUTUBE_URL;
  const twitterUrl = process.env.NEXT_PUBLIC_TWITTER_URL;
  const statusPageUrl = process.env.NEXT_PUBLIC_STATUS_PAGE_URL;

  return (
    <Box
      component="footer"
      bgcolor="black"
      py={2}
      px={2}
      color="white"
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box display="flex" flexDirection="row">
        <Box
          flex={1}
          display="flex"
          gap={1}
          flexDirection={isSmUp ? 'row' : 'column'}
        >
          <Link href={termsUrl} target="_blank">
            {t('common:footer:terms')}
          </Link>
          <Link href={privacyUrl} target="_blank">
            {t('common:footer:privacy')}
          </Link>
          {statusPageUrl ? (
            <Link href={statusPageUrl} target="_blank">
              Status
            </Link>
          ) : null}
        </Box>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
          flexDirection={isSmUp ? 'row' : 'column'}
        >
          <Typography variant="caption" color="textSecondary">
            {t('common:footer:followUs')}
          </Typography>
          <Box>
            {linkedInUrl && (
              <Link href={linkedInUrl} style={{ marginRight: 2 }}>
                <LinkedIn htmlColor="white" />
              </Link>
            )}

            {facebookUrl && (
              <Link href={facebookUrl} style={{ marginRight: 2 }}>
                <Facebook htmlColor="white" />
              </Link>
            )}

            {youtubeUrl && (
              <Link href={youtubeUrl} style={{ marginRight: 2 }}>
                <YouTube htmlColor="white" />
              </Link>
            )}

            {twitterUrl && (
              <Link href={twitterUrl} style={{ marginRight: 2 }}>
                <Twitter htmlColor="white" />
              </Link>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Image
          src="/sponsors/idb.png"
          width={100}
          height={32}
          style={{ filter: 'grayscale(1)', marginLeft: -8 }}
          alt="IDB Logo"
        />
        <Typography variant="caption" color="textSecondary">
          {t('common:footer:copy', { year: new Date().getFullYear() })}
        </Typography>
      </Box>
    </Box>
  );
};
