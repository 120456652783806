import { useQuery } from '@tanstack/react-query';
import { me } from '../services/userService';

const QUERY_KEY = ['me'];

export const useAuthService = () => {
  const query = useQuery(QUERY_KEY, me);

  return query;
};
