import { useQuery } from '@tanstack/react-query';
import { getCompany } from '../../../../services/companyService';
import { Id } from '../../../../types/Id';

export const QUERY_KEY = (id: Id) => ['GET_COMPANY', id];

export const useCompany = (id: Id) => {
  const query = useQuery(QUERY_KEY(id), () => getCompany(id), {
    enabled: !!id,
  });

  return query;
};
