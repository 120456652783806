import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Box,
  ListItemText,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import truncate from 'lodash.truncate';

import { useUserCompanies } from '../../services/hooks/useUserCompanies';
import { Id } from '../../types/Id';
import { useCurrentCompanyContext } from '../../context/CurrentCompany';
import { useRouter } from 'next/router';

export const CompanySwitcher = () => {
  const theme = useTheme();
  const router = useRouter();
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const { data: userCompaniesRes, isLoading: isLoadingUserCompanies } =
    useUserCompanies();
  const { setCurrentCompanyId, currentCompanyId, companyQuery } =
    useCurrentCompanyContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangeCurrentCompany = (id: Id) => {
    setCurrentCompanyId(id);
  };

  const routerPathIsMyCompanies = router.pathname.includes('/my-companies');

  if (routerPathIsMyCompanies) return null;

  const userCompanies =
    userCompaniesRes?.data.filter((c) => c.company.id !== currentCompanyId) ||
    [];

  if (userCompanies.length === 0) return null;

  return (
    <>
      <Box>
        <LoadingButton
          loading={companyQuery?.isLoading || isLoadingUserCompanies}
          variant="text"
          endIcon={<KeyboardArrowDown />}
          onClick={handleClick}
          aria-controls={open ? 'company-switcher-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          size="small"
        >
          {truncate(companyQuery?.data?.data?.caCompany?.name, {
            length: isSm ? 25 : 13,
          })}
        </LoadingButton>
      </Box>

      <Menu
        id="company-switcher-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {userCompanies.map((company) => (
          <MenuItem
            key={company.company.id}
            onClick={() => onChangeCurrentCompany(company.company.id)}
          >
            <ListItemText>{company?.caCompany?.name}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
