import { stringify } from 'qs';
import { authenticatedInstance, normalInstance } from './axiosInstance';
import { ArticlesResponseDto } from './dtos/articlesResponseDto';
import { format } from 'date-fns';
import { ArticlesFiltersDto } from '../components/pages/articles/ArticlesFilterDto';
import { EventsFiltersDto } from '../components/pages/events/EventsFiltersDto';

export const getArticles = (filters: ArticlesFiltersDto) => {
  const q = {
    ...filters,
    offset: filters.offset * filters.items_per_page,
  };
  return authenticatedInstance.get<ArticlesResponseDto>(
    `/contents/articles?${stringify(q)}`
  );
};

export const getLastArticles = (filters: ArticlesFiltersDto) => {
  const q = {
    ...filters,
    offset: filters.offset * filters.items_per_page,
  };
  return normalInstance.get<ArticlesResponseDto>(
    `/contents/lastArticles?${stringify(q)}`
  );
};

export const getArticle = (id, lang) => {
  return normalInstance.get(`/contents/article/${id}?lang=${lang}`);
};

export const getEvents = (filters: EventsFiltersDto) => {
  const q = {
    ...filters,
    offset: filters.offset * filters.items_per_page,
    from: filters.from ? format(filters.from, 'yyyy-MM-dd') : undefined,
    to: filters.to ? format(filters.to, 'yyyy-MM-dd') : undefined,
  };

  return authenticatedInstance.get(`/contents/events?${stringify(q)}`);
};

export const getLastEvents = (filters: EventsFiltersDto) => {
  const q = {
    ...filters,
    offset: filters.offset * filters.items_per_page,
    from: filters.from ? format(filters.from, 'yyyy-MM-dd') : undefined,
    to: filters.to ? format(filters.to, 'yyyy-MM-dd') : undefined,
  };

  return normalInstance.get(`/contents/lastEvents?${stringify(q)}`);
};

export const getEvent = (id, lang) => {
  return normalInstance.get(`/contents/event/${id}?lang=${lang}`);
};

export const getEventModalities = (locale: string) => {
  return normalInstance.get(`/contents/event/modalities?lang=${locale}`);
};

export const getCategories = (locale: string) => {
  return normalInstance.get(`/contents/categories?lang=${locale}`);
};

export const getAuthors = () => {
  return normalInstance.get(`/contents/authors`);
};

export const getUnreadEvents = async () => {
  return authenticatedInstance.get<string>(`/contents/events/unread`);
};

export const getUnreadArticles = async () => {
  return authenticatedInstance.get<string>(`/contents/articles/unread`);
};
