import { stringify } from 'qs';
import { Id } from '../types/Id';
import { authenticatedInstance } from './axiosInstance';
import { PurchaseAnnouncement } from '../types/PurchaseAnnouncement';
import { PagedResult } from './dtos/pagedResult';

export const getMyPurchaseAnnouncements = async (
  companyId: Id,
  itemsPerPage: number,
  offset: number
) => {
  const query = {
    items_per_page: itemsPerPage,
    offset: offset * itemsPerPage,
  };

  return await authenticatedInstance.get<PagedResult<PurchaseAnnouncement>>(
    `/companies/${companyId}/purchase_ads?${stringify(query)}`
  );
};

export const getUnreadPurchaseAnnouncementsCount = async () => {
  return authenticatedInstance.get<string>(`/purchase_ads/unread`);
};

export const getPurchaseAnnouncementsDetail = async (
  id: Id,
  currentCompanyId: Id
) => {
  const url = currentCompanyId
    ? `/purchase_ads/${id}?fromCompanyId=${currentCompanyId}`
    : `/purchase_ads/${id}`;

  return await authenticatedInstance.get<PurchaseAnnouncement>(url);
};

export const createPurchaseAnnouncement = async (
  data: Partial<PurchaseAnnouncement>
) => {
  return await authenticatedInstance.post(
    `/companies/${data.companyId}/purchase_ads`,
    data
  );
};

export const updatePurchaseAnnouncement = async (
  data: Partial<PurchaseAnnouncement>
) => {
  return await authenticatedInstance.patch(
    `/companies/${data.companyId}/purchase_ads/${data.id}`,
    data
  );
};

export const deletePurchaseAnnouncement = async (data: {
  companyId: Id;
  id: Id;
}) => {
  return await authenticatedInstance.delete(
    `/companies/${data.companyId}/purchase_ads/${data.id}`
  );
};

export const contactPurchaseAnnouncement = async (data: {
  fromCompanyId: Id;
  toCompanyId: Id;
  purchaseAdId: Id;
  message: string;
  turnstileToken: string;
}) => {
  return await authenticatedInstance.post(
    `/companies/${data.fromCompanyId}/contact`,
    {
      contactedCompanyId: data.toCompanyId,
      message: data.message,
      purchaseAdId: data.purchaseAdId,
      turnstileToken: data.turnstileToken,
    }
  );
};
