import { Roboto } from 'next/font/google';
import { createTheme } from '@mui/material/styles';
import type {} from '@mui/lab/themeAugmentation';

export const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#040D62',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2EAEC1',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: roboto.style.fontFamily,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 15,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'capitalize',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 1,
      },
    },
  },
});

theme.typography.h1 = {
  ...theme.typography.h1,
  fontSize: '4.2rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '6rem',
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  fontSize: '2.625rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '3.75rem',
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  fontSize: '2.1rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '3rem',
  },
};

theme.typography.h4 = {
  ...theme.typography.h4,
  fontSize: '1.4875rem',
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.9rem',
  },
};

theme.typography.h5 = {
  ...theme.typography.h5,
  fontSize: '1.2rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
  },
};

theme.typography.h6 = {
  ...theme.typography.h6,
  fontSize: '1rem',
  fontWeight: '400',
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};

export default theme;
