import { User } from '../context/Auth';
import { authenticatedInstance, normalInstance } from './axiosInstance';
import { GetCompanyResponseDto } from './dtos/GetCompanyResponseDto';
import { SignUpDto } from './dtos/signUpDto';

export const signUp = async (data: SignUpDto) => {
  return normalInstance.post('/users/signup', {
    ...data,
    country: data.country.id,
    gender: data.gender.id,
  });
};

export const signIn = async (
  email: string,
  pass: string,
  turnstileToken: string
) => {
  return authenticatedInstance.post('/users/signin', {
    mail: email,
    pass,
    turnstileToken,
  });
};

export const signout = async () => {
  return authenticatedInstance.post('/users/signout');
};

export const forgotPassword = async (email: string, turnstileToken: string) => {
  return normalInstance.post('/users/forgot_password', {
    email,
    turnstileToken,
  });
};

export const confirmUser = async (token: string) => {
  return normalInstance.post('/users/confirm_user', { token });
};

export const getUserCompanies = async () => {
  return authenticatedInstance.get<GetCompanyResponseDto[]>('/users/companies');
};

export const me = async () => {
  return authenticatedInstance.get<User>('/users/me');
};
