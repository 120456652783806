import { TFunction } from 'i18next';
import { routes } from './routes';

export const menuItems = (
  t: TFunction<'translation', undefined, 'translation'>,
  locale: string
) => [
  {
    id: 'explore',
    name: t('common:navBar:exploreDirectory'),
    href: routes.DIRECTORY,
  },
  {
    id: 'announcements',
    name: t('common:navBar:announcements'),
    href: routes.PURCHASE_ANNOUNCEMENTS,
  },
  {
    id: 'knowledge-center',
    name: t('common:navBar:knowledgeCenter'),
    href: routes.ARTICLES(locale),
  },
  { id: 'events', name: t('common:navBar:events'), href: routes.EVENTS },
];
