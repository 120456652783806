import {
  Badge,
  BadgeProps,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
} from '@mui/material';
import { Box } from '@mui/system';
import Link from 'next/link';

import { routes } from '../../config/routes';
import { Logo } from '../Logo';
import MuiNextLink from '../Link';
import { FC, ReactNode, useState } from 'react';
import { useRouter } from 'next/router';
import { AuthenticatedMenu } from './AuthenticatedMenu';
import { useAuth } from '../../context/Auth';
import { Menu as MenuIcon } from '@mui/icons-material';
import { menuItems } from '../../config/menuItems';
import { useTranslation } from 'next-i18next';
import { useNavbarNotifications } from './useNavbarNotifications';

interface Props {
  children?: ReactNode;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    fontSize: '10px',
    fontWeight: 'bold',
    right: -5,
    top: 0,
    padding: '0 4px',
  },
}));

const MobileStyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    fontSize: '10px',
    fontWeight: 'bold',
    right: -15,
    top: 8,
    padding: '0 4px',
  },
}));

export const NavBar: FC<Props> = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { isAuthenticated } = useAuth();
  const { unreadCompanies, unreadPurchasingAds, unreadEvents, unreadArticles } =
    useNavbarNotifications();
  const isSignInScreen = router.pathname.startsWith(routes.SIGN_IN());
  const isSignUpScreen = router.pathname.includes(routes.SIGN_UP());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUnreadCount = (id) => {
    if (id === 'explore') {
      return unreadCompanies;
    }

    if (id === 'announcements') {
      return unreadPurchasingAds;
    }

    if (id === 'knowledge-center') {
      return unreadArticles;
    }

    if (id === 'events') {
      return unreadEvents;
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={2}
      px={2}
      width="100%"
      sx={{ background: '#F2F2F2' }}
      position="fixed"
      height={72}
      zIndex={100}
    >
      <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              width: '25ch',
            },
          }}
        >
          {menuItems(t, router.locale).map((mi) => (
            <Link key={mi.id} href={mi.href} passHref>
              <MenuItem
                key={mi.id}
                onClick={handleClose}
                selected={router.pathname === mi.href}
              >
                <MobileStyledBadge
                  badgeContent={getUnreadCount(mi.id)}
                  color="info"
                  max={20}
                >
                  {mi.name.replace(new RegExp('<br/>', 'g'), ' ')}
                </MobileStyledBadge>
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </Box>
      <Box sx={{ flex: { xs: 1, lg: 1 } }}>
        <Link href={isAuthenticated ? routes.DASHBOARD : routes.ROOT}>
          <Box display="flex" ml={1}>
            <Logo />
          </Box>
        </Link>
      </Box>
      <Stack
        direction="row"
        spacing={3}
        flex={3}
        alignItems="center"
        justifyContent="center"
        sx={{ display: { xs: 'none', lg: 'flex' } }}
      >
        {menuItems(t, router.locale).map((mi) => (
          <StyledBadge
            key={mi.id}
            badgeContent={getUnreadCount(mi.id)}
            color="info"
            max={20}
          >
            <MuiNextLink
              href={mi.href}
              color="textSecondary"
              fontWeight="bold"
              sx={{ textDecoration: 'none', textAlign: 'center' }}
              dangerouslySetInnerHTML={{
                __html: mi.name,
              }}
            />
          </StyledBadge>
        ))}
      </Stack>

      {isAuthenticated ? (
        <AuthenticatedMenu />
      ) : (
        <Stack direction="row" spacing={1} flex={1} justifyContent="flex-end">
          {!isSignInScreen ? (
            <Link href={routes.SIGN_IN()} passHref>
              <Button variant="text" size="small">
                {t('common:navBar:signIn')}
              </Button>
            </Link>
          ) : null}

          {!isSignUpScreen ? (
            <Link href={routes.SIGN_UP()} passHref>
              <Button variant="contained" size="small">
                {t('common:navBar:signUp')}
              </Button>
            </Link>
          ) : null}
        </Stack>
      )}
    </Box>
  );
};
