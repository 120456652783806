import { AppProps } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import NextNProgress from 'nextjs-progressbar';

import { GoogleAnalytics } from 'nextjs-google-analytics';
import { appWithTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import './styles.css';
import theme from '../config/theme';
import createEmotionCache from '../config/createEmotionCache';
import { AuthProvider } from '../context/Auth';
import { MainLayout } from '../components/layouts/MainLayout';
import { CurrentCompanyProvider } from '../context/CurrentCompany';
import { useEffect } from 'react';
import { LANG_PREFERENCE_KEY } from '../components/LangSwitcher';
import { useRouter } from 'next/router';
import { setupLocale } from '../utils/yupLocales';
import { ChatProvider } from '../context/Chat';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const queryClient = new QueryClient();

function CustomApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const router = useRouter();

  const ComponentWithLayout = Component as React.ComponentType<any> & {
    Layout?: React.ComponentType<any>;
    layoutProps?: any;
  };

  const Layout = ComponentWithLayout.Layout || MainLayout;
  const layoutProps = ComponentWithLayout.layoutProps || {};

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localePreference = localStorage.getItem(LANG_PREFERENCE_KEY);

      if (localePreference !== null && router.locale !== localePreference) {
        router.push(router.pathname, router.asPath, {
          locale: localePreference,
        });
      }
    }
  }, [router]);

  useEffect(() => {
    setupLocale(router.locale);
  }, [router.locale]);

  return (
    <CacheProvider value={emotionCache}>
      <DndProvider backend={HTML5Backend}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <AuthProvider>
                <SnackbarProvider>
                  <CurrentCompanyProvider>
                    <ChatProvider>
                      <GoogleAnalytics trackPageViews />
                      <CssBaseline />
                      <Layout {...layoutProps}>
                        <NextNProgress />

                        <Component {...pageProps} />
                      </Layout>
                    </ChatProvider>
                  </CurrentCompanyProvider>
                </SnackbarProvider>
              </AuthProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </DndProvider>
    </CacheProvider>
  );
}

export default appWithTranslation(CustomApp);
