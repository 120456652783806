import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Typography,
} from '@mui/material';
import {
  Business,
  Logout,
  PrivacyTip,
  NextWeekRounded,
  ViewList,
  PersonSharp,
  Language,
  MailOutline,
  Home,
  AccountCircle,
} from '@mui/icons-material';
import { useAuth } from '../../context/Auth';
import { routes } from '../../config/routes';
import Link from 'next/link';
import { CompanySwitcher } from './CompanySwitcher';
import { useCurrentCompanyContext } from '../../context/CurrentCompany';
import { LangSwitcher } from '../LangSwitcher';
import { useTranslation } from 'next-i18next';
import { useChatContext } from '../../context/Chat';

export const AuthenticatedMenu = () => {
  const { t } = useTranslation('common');
  const { unreadCount } = useChatContext();
  const { currentCompanyId } = useCurrentCompanyContext();
  const { signout } = useAuth();
  const [langSwitcherOpened, setLangSwitcherOpened] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSignout = async () => {
    await signout();
  };

  const openLangSwitcher = () => {
    setLangSwitcherOpened(true);
  };

  const closeLangSwitcher = () => {
    setLangSwitcherOpened(false);
  };

  return (
    <Box flex={1}>
      <LangSwitcher open={langSwitcherOpened} handleClose={closeLangSwitcher} />
      <Box minWidth={125} display="flex" justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          <CompanySwitcher />
          <Link href={routes.INBOX} passHref>
            <IconButton size="small" sx={{ ml: 2 }}>
              <Badge badgeContent={unreadCount} color="info">
                <MailOutline />
              </Badge>
            </IconButton>
          </Link>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <AccountCircle />
          </IconButton>
        </Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuList>
            <Typography fontWeight="bold" px={2} pb={1}>
              My account
            </Typography>
            <Link href={routes.DASHBOARD} passHref>
              <MenuItem>
                <ListItemIcon>
                  <Home fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="textPrimary">
                    {t('common:menu:dashboard')}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Link>
            <Link href={routes.MY_COMPANIES} passHref>
              <MenuItem>
                <ListItemIcon>
                  <Business fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="textPrimary">
                    {t('common:menu:myCompanies')}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Link>
            <Link href={routes.MY_PROFILE} passHref>
              <MenuItem>
                <ListItemIcon>
                  <PersonSharp fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="textPrimary">
                    {t('common:menu:myProfile')}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Link>
            <Link href={routes.JOIN_A_COMPANY} passHref>
              <MenuItem>
                <ListItemIcon>
                  <NextWeekRounded fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="textPrimary">
                    {t('common:menu:joinCompany')}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Link>
            {currentCompanyId !== undefined ? (
              <Link
                href={routes.MY_COMPANIES_ACTIVITY_CENTER(currentCompanyId)}
                passHref
              >
                <MenuItem sx={{ mb: 1 }}>
                  <ListItemIcon>
                    <ViewList fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color="textPrimary">
                      {t('common:menu:activityCenter')}
                    </Typography>
                  </ListItemText>
                </MenuItem>
              </Link>
            ) : null}
            <Divider />
            <Typography fontWeight="bold" pt={1} px={2}>
              Settings
            </Typography>
            <MenuItem onClick={openLangSwitcher}>
              <ListItemIcon>
                <Language fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="textPrimary">
                  {t('common:menu:lang')}
                </Typography>
              </ListItemText>
            </MenuItem>
            <Divider />
            <Typography fontWeight="bold" px={2}>
              Others
            </Typography>
            <Link
              href={`mailto:${process.env.NEXT_PUBLIC_APP_CONTACT_EMAIL}`}
              passHref
            >
              <MenuItem>
                <ListItemIcon>
                  <PrivacyTip fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="textPrimary">
                    {t('common:menu:support')}
                  </Typography>
                </ListItemText>
              </MenuItem>
            </Link>
            <MenuItem onClick={onSignout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography color="textPrimary">
                  {t('common:menu:signOut')}
                </Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
};
