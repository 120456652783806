import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { GetCompanyResponseDto } from '../services/dtos/GetCompanyResponseDto';
import { useCompany } from '../components/pages/my-companies/add/useCompany';
import { Id } from '../types/Id';
import { UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useUserCompanies } from '../services/hooks/useUserCompanies';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';
import { useAuth } from './Auth';
import { addMonths } from 'date-fns';

interface CurrentCompanyContextProps {
  companyQuery: UseQueryResult<
    AxiosResponse<GetCompanyResponseDto, any>,
    unknown
  >;
  currentCompanyId: Id;
  setCurrentCompanyId: (id: Id) => void;
  updateCurrentCompany: () => void;
  initialized: boolean;
}

const CurrentCompanyContext = createContext<CurrentCompanyContextProps>({
  companyQuery: undefined,
  currentCompanyId: undefined,
  setCurrentCompanyId: undefined,
  updateCurrentCompany: undefined,
  initialized: false,
});

const LOCAL_STORAGE_KEY = 'currentCompanyId';

export const CurrentCompanyProvider = ({ children }) => {
  const { data: userCompaniesRes } = useUserCompanies();
  const [currentCompanyId, setCurrentCompanyId] = useState<Id>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const query = useCompany(currentCompanyId);
  const { user, isAuthenticated } = useAuth();

  const setCurrentCompanyWithLocalStorage = useCallback(
    (id: Id) => {
      if (id) {
        setCookie(LOCAL_STORAGE_KEY, `${user.id}-${id}`, {
          domain: process.env.COOKIE_DOMAIN
            ? process.env.COOKIE_DOMAIN
            : undefined,
          sameSite: 'lax',
          expires: addMonths(new Date(), 6),
        });
        setCurrentCompanyId(id);
      } else {
        deleteCookie(LOCAL_STORAGE_KEY);
        setCurrentCompanyId(undefined);
      }

      setInitialized(true);
    },
    [user]
  );

  const updateCurrentCompany = useCallback(() => {
    if (isAuthenticated && userCompaniesRes?.data) {
      const companyIdFromCookie = getCookie(LOCAL_STORAGE_KEY);

      if (companyIdFromCookie) {
        const [userId, companyId] = companyIdFromCookie.split('-');

        const companyFromLocalStorageIsInUserCompanies =
          userCompaniesRes.data.filter(
            (c) => c.company.id === Number(companyId)
          ).length > 0;

        if (userId === user.id && companyFromLocalStorageIsInUserCompanies) {
          setCurrentCompanyWithLocalStorage(companyId);
        } else {
          const firstCompanyId = userCompaniesRes?.data[0]?.company?.id;

          if (firstCompanyId) {
            setCurrentCompanyWithLocalStorage(
              userCompaniesRes.data[0].company.id
            );
          } else {
            setCurrentCompanyWithLocalStorage(undefined);
          }
        }
      } else {
        const firstCompanyId = userCompaniesRes?.data[0]?.company?.id;

        if (firstCompanyId) {
          setCurrentCompanyWithLocalStorage(
            userCompaniesRes.data[0].company.id
          );
        } else {
          setCurrentCompanyWithLocalStorage(undefined);
        }
      }
    }
  }, [
    isAuthenticated,
    setCurrentCompanyWithLocalStorage,
    user,
    userCompaniesRes,
  ]);

  useEffect(() => {
    updateCurrentCompany();
  }, [updateCurrentCompany, userCompaniesRes]);

  return (
    <CurrentCompanyContext.Provider
      value={{
        currentCompanyId: currentCompanyId
          ? Number(currentCompanyId)
          : undefined,
        setCurrentCompanyId: setCurrentCompanyWithLocalStorage,
        updateCurrentCompany,
        companyQuery: query,
        initialized,
      }}
    >
      {children}
    </CurrentCompanyContext.Provider>
  );
};

export const useCurrentCompanyContext = () => useContext(CurrentCompanyContext);
