import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  DialogContent,
  IconButton,
  Button,
  Stack,
} from '@mui/material';
import { useRouter } from 'next/router';
import { MouseEvent } from 'react';

export const LANG_PREFERENCE_KEY = 'locale';

export const LangSwitcher = ({ open, handleClose }) => {
  const router = useRouter();
  const locales = router.locales;
  const locale = router.locale;

  const onChangeLang = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    locale: string
  ) => {
    e.preventDefault();

    localStorage.setItem(LANG_PREFERENCE_KEY, locale);
    router.push(router.pathname, router.asPath, { locale });
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <DialogTitle
        component={Box}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h5">Change language</Typography>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          {locales.map((l) => {
            if (l === locale) {
              return (
                <Button key={l} variant="outlined" fullWidth disabled>
                  {l.toUpperCase()}
                </Button>
              );
            }

            return (
              <Button
                key={l}
                variant="outlined"
                fullWidth
                onClick={(event) => onChangeLang(event, l)}
              >
                {l.toUpperCase()}
              </Button>
            );
          })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
