import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getUnreadCompaniesCount } from '../../services/companyService';
import { getUnreadPurchaseAnnouncementsCount } from '../../services/purchaseAnnouncementsService';
import { useAuth } from '../../context/Auth';
import { useCurrentCompanyContext } from '../../context/CurrentCompany';
import {
  getUnreadArticles,
  getUnreadEvents,
} from '../../services/contentsService';

export const useUnreadCompanies = () => {
  const { isAuthenticated } = useAuth();
  const { currentCompanyId } = useCurrentCompanyContext();
  const client = useQueryClient();

  const QUERY_KEY = ['GET_UNREAD_COMPANIES', currentCompanyId];
  const query = useQuery(QUERY_KEY, getUnreadCompaniesCount, {
    enabled: isAuthenticated,
  });

  const invalidate = () => {
    client.invalidateQueries(QUERY_KEY);
  };

  return { query, invalidate };
};

export const useUnreadPurchaseAds = () => {
  const { isAuthenticated } = useAuth();
  const { currentCompanyId } = useCurrentCompanyContext();
  const client = useQueryClient();

  const QUERY_KEY = ['GET_UNREAD_PURCHASING_ADS', currentCompanyId];
  const query = useQuery(QUERY_KEY, getUnreadPurchaseAnnouncementsCount, {
    enabled: isAuthenticated,
  });

  const invalidate = () => {
    client.invalidateQueries(QUERY_KEY);
  };

  return { query, invalidate };
};

export const useUnreadEvents = () => {
  const { isAuthenticated } = useAuth();
  const { currentCompanyId } = useCurrentCompanyContext();
  const client = useQueryClient();

  const QUERY_KEY = ['GET_UNREAD_EVENTS', currentCompanyId];
  const query = useQuery(QUERY_KEY, getUnreadEvents, {
    enabled: isAuthenticated,
  });

  const invalidate = () => {
    client.invalidateQueries(QUERY_KEY);
  };

  return { query, invalidate };
};

export const useUnreadArticles = () => {
  const { isAuthenticated } = useAuth();
  const { currentCompanyId } = useCurrentCompanyContext();
  const client = useQueryClient();

  const QUERY_KEY = ['GET_UNREAD_ARTICLES', currentCompanyId];
  const query = useQuery(QUERY_KEY, getUnreadArticles, {
    enabled: isAuthenticated,
  });

  const invalidate = () => {
    client.invalidateQueries(QUERY_KEY);
  };

  return { query, invalidate };
};

export const useNavbarNotifications = () => {
  const { query: companiesQuery } = useUnreadCompanies();
  const { query: purchaseQuery } = useUnreadPurchaseAds();
  const { query: articlesQuery } = useUnreadArticles();
  const { query: eventsQuery } = useUnreadEvents();

  return {
    unreadCompanies: companiesQuery?.data?.data || 0,
    unreadPurchasingAds: purchaseQuery?.data?.data || 0,
    unreadArticles: articlesQuery?.data?.data || 0,
    unreadEvents: eventsQuery?.data?.data || 0,
  };
};
