import { stringify } from 'qs';
import {
  ImporterCompanyCreation,
  ManufacturerCompanyCreation,
} from '../types/CompanyCreation';
import { EquipmentSpecialty } from '../types/EquipmentSpecialty';
import { Experience } from '../types/experience';
import { authenticatedInstance, normalInstance } from './axiosInstance';
import { Membership } from '../types/membership';
import { CreateCompanyCertificationDto } from './dtos/createCompanyCertificationDto';
import { CreateCompanyMembershipDto } from './dtos/createCompanyMembershipDto';
import { ProductionAbilityCategory } from '../types/ProductionAbilityCategory';
import {
  CreateImporterDto,
  CreateManufacturerDto,
} from './dtos/CreateCompanyDto';
import { GetCompanyResponseDto } from './dtos/GetCompanyResponseDto';
import { PagedResult } from './dtos/pagedResult';
import { EquipmentType } from '../types/EquipmentType';
import { CompanyCertification } from '../types/CompanyCertification';
import { Id } from '../types/Id';
import { Attachment } from '../types/MediaImage';
import { User } from '../types/User';
import { getUserCompanies } from './userService';
import { TextileCategory } from '../types/TextileCategory';
import { CompaniesFiltersDto } from './directoryService';

export const getCompany = async (id: Id) => {
  return authenticatedInstance.get<GetCompanyResponseDto>(`/companies/${id}`);
};

export const getUnreadCompaniesCount = async () => {
  return authenticatedInstance.get<string>(`/companies/unread`);
};

export const buildCompanyAvatarUrl = (id: Id) => {
  return `${process.env.NEXT_PUBLIC_API_BASE_URL}/companies/${id}/avatar`;
};

export const getTextileCategory = () => {
  return authenticatedInstance.get<TextileCategory[]>(
    `/companies/config/textile-categories`
  );
};

export const getTranslatedText = async (text: string) => {
  return await authenticatedInstance.post(`/misc/translate`, {
    text,
  });
};

export const addManufacturer = async (data: ManufacturerCompanyCreation) => {
  const socialWebsites = [];

  if (data.linkedin) {
    socialWebsites.push({
      site: 'ln',
      url: data.linkedin,
    });
  }

  if (data.twitter) {
    socialWebsites.push({
      site: 'tw',
      url: data.twitter,
    });
  }

  if (data.facebook) {
    socialWebsites.push({
      site: 'fb',
      url: data.facebook,
    });
  }

  if (data.instagram) {
    socialWebsites.push({
      site: 'ig',
      url: data.instagram,
    });
  }

  const dto = {
    ca_company_id: data.ca_company_id,
    businessInCafta:
      data.businessInCafta === 'Yes' || data.businessInCafta === 'Si',
    type: data.type,
    name: data.name,
    headquarterId: Number(data.country_id),
    email: data.email,
    phone_number: data.phone_number,
    website: data.website,
    address: data.address,
    address_line2: data.address_line2 || '',
    year_established: data.year_established.toString(),
    photo_url: data.photo_url ? data.photo_url : undefined,
    bussiness_description: data.bussiness_description,
    employees_number: data.employees_number.toString(),
    company_presence_countries: data.countriesOfOperation.map((c) => {
      return { tid: `${c.id}` };
    }),
    socialWebsites: socialWebsites,
    textiles_category: Number(data.textiles_category),
    turnstileToken: data.turnstileToken,
  } as CreateManufacturerDto;

  return authenticatedInstance.post('/companies', dto);
};

export const addImporter = async (data: ImporterCompanyCreation) => {
  const socialWebsites = [];

  if (data.linkedin) {
    socialWebsites.push({
      site: 'ln',
      url: data.linkedin,
    });
  }

  if (data.twitter) {
    socialWebsites.push({
      site: 'tw',
      url: data.twitter,
    });
  }

  if (data.facebook) {
    socialWebsites.push({
      site: 'fb',
      url: data.facebook,
    });
  }

  if (data.instagram) {
    socialWebsites.push({
      site: 'ig',
      url: data.instagram,
    });
  }

  const dto = {
    ca_company_id: data.ca_company_id,
    usImporter: data.usImporter === 'Yes' || data.usImporter === 'Si',
    businessInCafta: true,
    name: data.name,
    headquarterId: Number(data.country_id),
    email: data.email,
    phone_number: data.phone_number,
    photo_url: data.photo_url ? data.photo_url : undefined,
    website: data.website,
    bussiness_description: data.bussiness_description,
    socialWebsites: socialWebsites,
    textiles_category: Number(data.textiles_category),
    turnstileToken: data.turnstileToken,
  } as CreateImporterDto;

  return authenticatedInstance.post('/companies', dto);
};

export const editCompany = async (values: {
  data: ManufacturerCompanyCreation | ImporterCompanyCreation;
  companyId: string;
  textiles_category: 'Manufacturer' | 'Importer';
}) => {
  const response = await getTextileCategory();
  const categoryId = response.data.find(
    (cat) => cat.name === values.textiles_category
  ).id;
  const socialWebsites = [];

  if (values.data.linkedin) {
    socialWebsites.push({
      site: 'ln',
      url: values.data.linkedin,
    });
  }

  if (values.data.twitter) {
    socialWebsites.push({
      site: 'tw',
      url: values.data.twitter,
    });
  }

  if (values.data.facebook) {
    socialWebsites.push({
      site: 'fb',
      url: values.data.facebook,
    });
  }

  if (values.data.instagram) {
    socialWebsites.push({
      site: 'ig',
      url: values.data.instagram,
    });
  }

  let dto;
  if (values.textiles_category === 'Importer') {
    const importerData = values.data as ImporterCompanyCreation;
    dto = {
      ca_company_id: importerData.ca_company_id,
      usImporter:
        importerData.usImporter === 'Yes' || importerData.usImporter === 'Si',
      businessInCafta: true,
      name: importerData.name,
      headquarterId: Number(importerData.country_id),
      email: importerData.email,
      phone_number: importerData.phone_number,
      photo_url: importerData.photo_url ? importerData.photo_url : undefined,
      website: importerData.website,
      bussiness_description: importerData.bussiness_description,
      socialWebsites: socialWebsites,
      textiles_category: Number(categoryId),
    } as CreateImporterDto;
  } else if (values.textiles_category === 'Manufacturer') {
    const manufacturerData = values.data as ManufacturerCompanyCreation;
    dto = {
      ca_company_id: manufacturerData.ca_company_id,
      businessInCafta:
        manufacturerData.businessInCafta === 'Yes' ||
        manufacturerData.businessInCafta === 'Si',
      name: manufacturerData.name,
      headquarterId: Number(manufacturerData.country_id),
      email: manufacturerData.email,
      phone_number: manufacturerData.phone_number,
      website: manufacturerData.website,
      address: manufacturerData.address,
      address_line2: manufacturerData.address_line2 || '',
      year_established: manufacturerData.year_established.toString(),
      photo_url: manufacturerData.photo_url
        ? manufacturerData.photo_url
        : undefined,
      bussiness_description: manufacturerData.bussiness_description,
      employees_number: manufacturerData.employees_number.toString(),
      company_presence_countries: manufacturerData.countriesOfOperation.map(
        (c) => {
          return { tid: `${c.id}` };
        }
      ),
      socialWebsites: socialWebsites,
      textiles_category: Number(categoryId),
    } as CreateManufacturerDto;
  } else {
    throw 'Invalid textile category';
  }

  return authenticatedInstance.put(`/companies/${values.companyId}`, dto);
};

export const getCACompaniesWithNoCompanyCreated = async () => {
  const allCompanies = await getUserCompanies();
  const caCompanies = await authenticatedInstance.get(
    'users/existingCACompanies'
  );

  return caCompanies?.data.filter((caCompany) => {
    return !allCompanies?.data.some(
      (userCompany) => userCompany.caCompany.id === caCompany.id
    );
  });
};

export const getCACompany = async (companyId) => {
  return await authenticatedInstance.get(`/companies/ca/${companyId}`);
};

export const checkCompanyNameUniqueness = async (data: {
  name: string;
  ca_company_id?: number;
}) => {
  const response = await authenticatedInstance.post(
    '/companies/checkCompanyNameUniqueness',
    data
  );
  return response.data;
};

export const getLastCompanies = (filters: CompaniesFiltersDto) => {
  const categories = filters.categories || [];

  const query = stringify({
    ...filters,
    categories: categories.filter((c) => c !== '-1').join('.'),
  });

  return normalInstance.get<GetCompanyResponseDto[]>(
    `/companies/last?${query}`
  );
};

export const getExperiencesList = async (companyId: string) => {
  return await authenticatedInstance.get<Experience[]>(
    `/companies/${companyId}/experiences/list`
  );
};

export const saveExperiences = async (data: any) => {
  const response = await authenticatedInstance.post(
    `/companies/${data.companyId}/experiences`,
    data.values
  );

  return response.data;
};
export const removeExperience = async (id: string) => {
  await new Promise((resolve) => setTimeout(resolve, 1500));

  return true;
};

export const getEquipmentTypes = async (companyId: string) => {
  const query = {
    company_id: companyId,
  };

  return authenticatedInstance.get<EquipmentType[]>(
    `/equipments-types/notUsed?${stringify(query)}`
  );
};

export const getEquipmentsSpecialties = async (
  companyId: string,
  itemsPerPage: number,
  offset: number
) => {
  const query = {
    items_per_page: itemsPerPage,
    offset: offset,
  };

  return authenticatedInstance.get<PagedResult<EquipmentSpecialty[]>>(
    `/companies/${companyId}/equipments?${stringify(query)}`
  );
};

export const getEquipmentsSpecialtiesList = async (companyId: string) => {
  return authenticatedInstance.get<PagedResult<EquipmentSpecialty[]>>(
    `/companies/${companyId}/equipments/list`
  );
};

export const addEquipmentSpecialty = async (
  data: Partial<EquipmentSpecialty>
) => {
  return authenticatedInstance.post<EquipmentSpecialty>(
    `/companies/${data.companyId}/equipments`,
    data
  );
};

export const removeEquipmentSpecialty = async (values: any) => {
  const { companyId, id } = values;
  return authenticatedInstance.delete<boolean>(
    `/companies/${companyId}/equipments/${id}`
  );
};

export const getCertifications = async (
  companyId: string,
  itemsPerPage: number,
  offset: number
) => {
  return await authenticatedInstance.get<PagedResult<CompanyCertification>>(
    `/companies/${companyId}/certifications?items_per_page=${itemsPerPage}&offset=${offset}`
  );
};

export const getCertificationsList = async (companyId: string) => {
  return await normalInstance.get<PagedResult<CompanyCertification>>(
    `/companies/${companyId}/certifications/list`
  );
};

export const addCertificationToCompany = async (
  dto: CreateCompanyCertificationDto
) => {
  const response = await authenticatedInstance.post(
    `/companies/${dto.companyId}/certifications`,
    dto.data
  );

  return response.data;
};

export const removeCertification = async (data: {
  companyId: string | string[];
  certificationId: number;
}) => {
  const response = await authenticatedInstance.delete(
    `/companies/${data.companyId}/certifications/${data.certificationId}`
  );

  return response.data;
};

export const getMemberships = async (
  companyId: string,
  itemsPerPage: number,
  offset: number
) => {
  return await authenticatedInstance.get<PagedResult<Membership>>(
    `/companies/${companyId}/memberships?items_per_page=${itemsPerPage}&offset=${offset}`
  );
};

export const getMembershipsList = async (companyId: string) => {
  return await authenticatedInstance.get<PagedResult<Membership>>(
    `/companies/${companyId}/memberships/list`
  );
};

export const addMembership = async (data: Membership) => {
  await new Promise((resolve) => setTimeout(resolve, 1500));

  return {
    id: 5,
    name: 'Membership 5',
  };
};

export const addMembershipToCompany = async (
  dto: CreateCompanyMembershipDto
) => {
  const response = await authenticatedInstance.post(
    `/companies/${dto.companyId}/memberships`,
    dto.data
  );
  return response.data;
};

export const removeMembership = async (data: {
  companyId: string | string[];
  membershipId: number;
}) => {
  const response = await authenticatedInstance.delete(
    `/companies/${data.companyId}/memberships/${data.membershipId}`
  );

  return response.data;
};

export const getProductionAbilitiesCategories = async (
  parentId?: number | string
) => {
  return authenticatedInstance.get<ProductionAbilityCategory[]>(
    `/product-ability-categories?parentId=${parentId}`
  );
};

export interface Ability {
  id?: number;
  productAbilityCategoryId: number;
  warpFillTypes?: string;
  weights?: string;
  dyeTechs?: string;
  dyeTechsOther?: string;
  finishes?: string;
  exportLotSizes?: string;
  dimensionalStabilities?: string;
  dimensionalStabilitiesOther?: string;
  weaves?: string;
  weavesOther?: string;
  knitTypes?: string;
  flatKnits?: string;
  flatKnitsOther?: string;
  knitWithSpanderFeeders?: string;
  singleOrDoubleKnits?: string;
  singleOrDoubleKnitsOther?: string;
  singles?: string;
  singlesOther?: string;
  doubles?: string;
  doublesOther?: string;
  gauge?: string;
  diameter?: string;
}

export interface CompanyProductionAbilityResponse {
  id: number;
  nameEs: string;
  nameEn: string;
  nameBr: string;
  icon?: Attachment;
  isLeaf: boolean;
  extraInfoKey?: 'woven' | 'warp' | 'circular';
  children: CompanyProductionAbilityResponse[];
  abilities: Ability[];
}

export const getCompanyProductionAbilitiesTree = async (companyId: Id) => {
  return authenticatedInstance.get<CompanyProductionAbilityResponse[]>(
    `/companies/${companyId}/product_abilities/tree`
  );
};

export const getCompanyProductionAbilities = async (companyId: Id) => {
  return authenticatedInstance.get<Ability[]>(
    `/companies/${companyId}/product_abilities`
  );
};

export const postCompanyProductionAbilities = async (
  companyId: Id,
  payload: Ability[]
) => {
  return authenticatedInstance.post(
    `/companies/${companyId}/product_abilities`,
    payload
  );
};

export interface statusUserDto {
  data: { userId: number; email: string; name: string };
  companyId: string | string[];
}

export const getUsers = async (companyId: string) => {
  return await authenticatedInstance.get<User[]>(
    `/companies/${companyId}/users`
  );
};

export interface removeUserDto {
  companyId: string | string[];
  userId: number;
}

export const removeUser = async (data: {
  companyId: string | string[];
  userId: number;
}) => {
  return authenticatedInstance.delete<boolean>(
    `/companies/${data.companyId}/charges`,
    { data: { userId: data.userId } }
  );
};

export const acceptUser = async (user: statusUserDto) => {
  const response = await authenticatedInstance.post(
    `/companies/${user.companyId}/charges/approve`,
    user.data
  );
  return response.data;
};

export const rejectUser = async (user: statusUserDto) => {
  const response = await authenticatedInstance.post(
    `/companies/${user.companyId}/charges/reject`,
    user.data
  );
  return response.data;
};
