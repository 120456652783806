import { stringify } from 'qs';
import { Id } from '../types/Id';

export const routes = {
  ROOT: '/',
  SIGN_IN: (redirect?: string) => {
    const query = {
      redirect,
    };

    const stringifiedQuery = stringify(query);

    return stringifiedQuery
      ? `/auth/signin?${stringifiedQuery}`
      : '/auth/signin';
  },
  SIGN_UP: (email?: string) =>
    email ? `/auth/signup?email=${email}` : `/auth/signup`,
  FORGOT_PASSWORD: '/auth/forgot-password',
  EMAIL_CONFIRMATION: '/auth/email-confirmation',

  TERMS: '/terms-and-conditions',
  PRIVACY_POLICY: '/privacy-policy',

  EVENTS: '/events',
  EVENT_DETAILS: (id: Id, lang: string) => `/events/${id}?lang=${lang}`,
  ARTICLES: (lang?: string) => (lang ? `/articles?lang=${lang}` : '/articles'),
  ARTICLE_DETAILS: (id: Id, lang: string) => `articles/${id}?lang=${lang}`,

  DIRECTORY: '/directory/list',
  PURCHASE_ANNOUNCEMENTS: '/directory/purchase-announcements',
  MY_ANNOUNCEMENTS: '/directory/my-announcements',

  PURCHASE_ANNOUNCEMENTS_DETAILS: (id: Id) =>
    `/directory/purchase-announcements/${id}`,

  CONNECT_AMERICAS: process.env.NEXT_PUBLIC_CONNECT_AMERICAS_URL || '#',

  COMPANY_PROFILE_BY_SLUG: (slug: string) => `/${slug}`,
  COMPANY_PROFILE_BY_ID: (id: Id) => `/companies/${id}`,
  COMPANY_PROFILE_GALLERY: (id: Id) => `/companies/${id}/gallery`,

  MY_COMPANIES: '/my-companies',
  MY_COMPANIES_VERIFICATION: '/my-companies/verification',
  MY_COMPANIES_NEW_COMPANY: (baseCaCompanyId?: Id) =>
    baseCaCompanyId
      ? `/my-companies/new?baseCompanyId=${baseCaCompanyId}`
      : `/my-companies/new`,
  MY_COMPANIES_NEW_COMPANY_MANUFACTURER: (
    categoryId: Id,
    baseCaCompanyId?: Id
  ) =>
    baseCaCompanyId
      ? `/my-companies/new/manufacturer?categoryId=${categoryId}&baseCompanyId=${baseCaCompanyId}`
      : `/my-companies/new/manufacturer?categoryId=${categoryId}`,
  MY_COMPANIES_NEW_COMPANY_IMPORTER: (categoryId: Id, baseCaCompanyId?: Id) =>
    baseCaCompanyId
      ? `/my-companies/new/importer?categoryId=${categoryId}&baseCompanyId=${baseCaCompanyId}`
      : `/my-companies/new/importer?categoryId=${categoryId}`,
  MY_COMPANIES_CA_COMPANIES: '/my-companies/new/caCompanies',

  MY_COMPANIES_COMPANY_PROFILE: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/profile`,
  MY_COMPANIES_COMPANY_GALLERY: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/gallery`,
  MY_COMPANIES_COMPANY_PRODUCTION_ABILITIES: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/production-abilities`,
  MY_COMPANIES_COMPANY_PRODUCTION_ABILITIES_EDIT: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/production-abilities/edit`,
  MY_COMPANIES_COMPANY_EXPERIENCE: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/experience`,
  MY_COMPANIES_COMPANY_CERTIFICATIONS: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/certifications`,
  MY_COMPANIES_COMPANY_CERTIFICATIONS_ADD: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/certifications/add`,
  MY_COMPANIES_COMPANY_MEMBERSHIPS: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/memberships`,
  MY_COMPANIES_COMPANY_MEMBERSHIPS_ADD: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/memberships/add`,
  MY_COMPANIES_COMPANY_EQUIPMENT_SPECIALTIES: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/equipment-specialties`,
  MY_COMPANIES_COMPANY_EQUIPMENT_SPECIALTIES_ADD: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/equipment-specialties/add`,
  MY_COMPANIES_COMPANY_USERS: (id: Id) => `${routes.MY_COMPANIES}/${id}/users`,
  MY_COMPANIES_ACTIVITY_CENTER: (id: Id) =>
    `${routes.MY_COMPANIES}/${id}/activity-center`,

  MY_PROFILE: '/my-profile',
  JOIN_A_COMPANY: '/join-company',

  INBOX: '/inbox',
  DASHBOARD: '/dashboard',
};
