import Image from 'next/image';
import caPng from '../public/logo-2.png';
import caPngMobile from '../public/logo-mobile.png';
import { useMediaQuery, useTheme } from '@mui/material';

export const Logo = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return <Image src={caPngMobile} width={40} height={40} alt="logo" />;
  }

  return <Image src={caPng} width={200} height={59} alt="logo" />;
};
