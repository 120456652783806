import { setLocale } from 'yup';
import { es as esLocale } from 'yup-locales';

const esUrlErrorMessage = 'Debe ser una URL válida';
const enUrlErrorMessage = 'Must be a valid URL';
const caUrl = process.env.NEXT_PUBLIC_CONNECT_AMERICAS_URL || '#';

export const setupLocale = (locale) => {
  if (locale === 'es') {
    setLocale(es);
  } else {
    setLocale(en);
  }
};
export const es = {
  ...esLocale,
  mixed: {
    ...esLocale.mixed,
    required: 'El campo es requerido',
  },
  number: {
    ...esLocale.number,
    min: 'Debe ser mayor que ${min}',
    max: 'Debe ser menor que ${max}',
    positive: 'Este campo debe ser positivo',
  },
  string: {
    ...esLocale.string,
    matches: esUrlErrorMessage,
    max: 'El campo debe tener como máximo ${max} caracteres',
    companyNameIsRequired: `Esta empresa ya existe en <a href="${caUrl}" target="_blank">ConnectAmericas</a>. Si tu no la has creado, ponte en contacto a <a href="mailto:${process.env.NEXT_PUBLIC_APP_CONTACT_EMAIL}">${process.env.NEXT_PUBLIC_APP_CONTACT_EMAIL}</a>`,
    selectFileOrUrl: 'Por favor seleccione un archivo o un link a youtube',
    email: 'El campo debe ser un correo electrónico válido',
  },
  array: {
    min: 'El campo debe tener por lo menos ${min} item',
    max: 'El campo debe tener como maximo ${max} items',
  },
};

export const en = {
  mixed: { required: 'This field is required' },
  number: {
    min: 'Should be greater than ${min}',
    max: 'Should be less than ${max}',
    positive: 'This field should be positive',
  },
  string: {
    matches: enUrlErrorMessage,
    max: 'This field must have a maximum of ${max} characters',
    companyNameIsRequired: `This company already exists on <a href="${caUrl}" target="_blank">ConnectAmericas</a>. If you have not created it, contact us at <a href="mailto:${process.env.NEXT_PUBLIC_APP_CONTACT_EMAIL}">${process.env.NEXT_PUBLIC_APP_CONTACT_EMAIL}</a>`,
    selectFileOrUrl: 'Please select a file or enter a youtube url',
    email: 'This field should be a valid email',
  },
  array: {
    min: 'This field should have at least ${min} items',
    max: 'This field should have a maximum of ${max} items',
  },
};
