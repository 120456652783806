import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Footer } from '../Footer';
import { NavBar } from '../NavBar/NavBar';

interface Props {
  children: ReactNode;
}

export const MainLayout: FC<Props> = ({ children }) => {
  return (
    <>
      <NavBar />
      <Box minHeight="100%" display="flex" flexDirection="column" pt="72px">
        <Box flex={1} display="flex" flexDirection="column">
          {children}
        </Box>
        <Footer />
      </Box>
    </>
  );
};
