import { AxiosResponse } from 'axios';
import { createContext, FC, ReactNode, useContext } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { useAuthService } from '../hooks/useAuthService';
import {
  signout as ApiSignout,
  signIn as ApiSignIn,
} from '../services/userService';
import { useRouter } from 'next/router';
import { routes } from '../config/routes';

export interface User {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  language: string;
  gender: string;
  picture: string;
  country: string;
  country_code: string;
  country_name: string;
  charges: any[];
  social_websites: any[];
  profession: string;
  educational_level: any;
  more_about: string;
  profile_completed: number;
  can_use_private_messages: boolean;
  pm4r_certificated_user: any;
  communities: any;
  favorite_projects: any;
  afdb_additional_info: any;
  enabled_push_notifications: boolean;
  enabled_push_notifications_bta: boolean;
  bta_emails_frequency: number;
  has_logged_on_ca: boolean;
  is_admin: boolean;
  is_reports_admin: boolean;
  has_accepted_tyc: boolean;
  likes: any[];
  ptp_lowinterest_alerts_enabled: any;
}

interface AuthContextProps {
  user: User;
  isAuthenticated: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: UseQueryResult<AxiosResponse<User, any>, unknown>;
  signout: () => Promise<any>;
  signIn: (email: string, pass: string, turnstileToken: string) => Promise<any>;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextProps>({
  user: undefined,
  isAuthenticated: false,
  query: undefined,
  signout: undefined,
  signIn: undefined,
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const query = useAuthService();
  const router = useRouter();
  const user = query?.data?.data;

  const signout = async () => {
    const res = await ApiSignout();
    query.refetch();

    router.replace(routes.ROOT);

    return res;
  };

  const signIn = async (
    email: string,
    pass: string,
    turnstileToken: string
  ) => {
    const res = await ApiSignIn(email, pass, turnstileToken);

    query.refetch();

    return res;
  };

  return (
    <AuthContext.Provider
      value={{
        user: query?.data?.data,
        isAuthenticated: !!user,
        query,
        signout,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
